.environment-container {
  .discovered-assets {
    .infra-toplogy-view {
      height: 100%;
      .gmnoprint {
        position: absolute;
        bottom: 0px;
        right: 0px;
        width: 30px;
        z-index: 1;

        .gmnoprint-plus-minus {
          display: block;
          width: 100%;
          margin-bottom: 10px;
          box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04);

          .btn {
            display: block;
            color: #023aff;
            font-size: 14px;
            line-height: 30px;
            width: 30px;
            height: 30px;
            border: none;
            cursor: pointer;
            padding: 0;
            text-align: center;
            background-color: #ffffff;
          }
        }

        .gmnoprint-map {
          display: block;
          width: 100%;
          margin-bottom: 10px;
          box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04);

          .btn {
            display: block;
            color: #023aff;
            font-size: 14px;
            line-height: 30px;
            width: 30px;
            height: 30px;
            border: none;
            padding: 0;
            text-align: center;
            background-color: #ffffff;
          }
        }
      }
      .services-text-box {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: left;
        position: relative;
        // margin-right: 80px;
        vertical-align: text-top;
        width: 140px;
        height: 55px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        color: #ffffff;
        background: #384cff;
        border-radius: 10px;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04);
        &.active {
          border-color: #00861b;
        }
        .account-image {
          padding: 4px;
          width: 30px;
          height: 30px;
          border-radius: 3px;
          background: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 8px;
        }
        .account-id {
          width: 80px;
          span {
            width: 80px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .global-servies {
        display: inline-flex;
        width: 150px;
        flex-wrap: wrap;
        margin-right: 0px;

        &.cluster-servies {
          width: 26%;
          &::after {
            top: auto;
            bottom: 45px;
          }
        }

        &:last-child {
          margin-right: 0;

          &::after {
            display: none;
          }
        }

        .global-servies-menu {
          margin-top: 0;
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          border-radius: 10px;
          overflow: hidden;
          background-color: #ffffff;
          border: 1px solid #a5a5d7;
          box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);

          label {
            display: flex;
            justify-content: center;
            text-align: center;
            flex-wrap: wrap;
            width: 100%;
            height: auto;
            padding-top: 7px;
            padding-bottom: 7px;
            font-size: 12px;
            line-height: 16px;
            font-weight: 600;
            color: #383874;
            cursor: pointer;
            border-bottom: 1px solid #a5a5d7;

            &:last-child {
              border: none;
            }

            &.active {
              color: #ffffff;
              background-color: #416bff;
            }

            span {
              display: inline-block;
              width: 12px;
              margin-right: 5px;
              margin-top: -2px;

              img {
                max-width: 100%;
                height: auto;
                vertical-align: middle;
              }
            }
          }
        }

        ul {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          margin-bottom: 15px;
          list-style: none;
          position: relative;
          border-radius: 10px;
          border: 1px solid #a5a5d7;
          box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);

          li {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            height: 30px;
            font-size: 12px;
            line-height: 30px;
            font-weight: 600;
            color: #383874;
            padding-left: 12px;
            cursor: pointer;
            position: relative;
            border-bottom: 1px solid #a5a5d7;
            p {
              width: calc(100% - 25px);
              padding: 0;
              margin: 0;
              color: #383874;
              font-size: 12px;
              line-height: 30px;
              font-weight: 600;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            &:first-child {
              border-top-left-radius: 9px;
              border-top-right-radius: 9px;
            }

            &:last-child {
              border-bottom: none;
              border-bottom-right-radius: 9px;
              border-bottom-left-radius: 9px;
            }

            &.active {
              color: #ffffff;
              background-color: #384cff;
              padding-left: 8px;
              p {
                color: #ffffff;
              }
              span {
                width: 20px;
                height: 20px;
                padding: 3px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #ffffff;
                border-radius: 50%;
              }
              &::after {
                opacity: 1;
              }
            }

            span {
              display: inline-flex;
              width: 12px;
              margin-right: 5px;
              // margin-top: -2px;

              img {
                max-width: 100%;
                height: auto;
                vertical-align: middle;
              }
            }
          }
        }
      }
    }

    .environment-table-section {
      padding-top: 0;
      padding-bottom: 0;
      height: auto;

      .table {
        table {
          thead {
            tr {
              background-color: #ffffff;
              border-bottom: 1px dotted #b3b3b3;
              th {
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 15px;
                padding-right: 15px;
                font-size: 13px;
                line-height: 25px;
                font-weight: 500;
                width: auto;
                white-space: nowrap;

                .environment-image {
                  margin: 0px;
                  width: 24px;
                  height: 24px;
                  padding: 2px;
                }
              }
            }
          }

          tbody {
            tr {
              td {
                padding-left: 15px;
                padding-right: 15px;
                width: auto;
                white-space: nowrap;

                .availability-box {
                  display: inline-block;
                  cursor: pointer;
                  span {
                    display: inline-block;
                    border-radius: 50%;

                    vertical-align: middle;
                    width: 20px;
                    height: 20px;
                    &.green {
                      background-color: #00b929;
                    }
                    &.orange {
                      background-color: #ffba69;
                    }
                  }
                }

                
              }
            }
          }
        }
      }
    }

    .global-services-fliter {
      display: block;
      width: 100%;
      border-radius: 8px;
      background-color: #ffffff;
      margin-top: 0px;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04);

      .heading {
        border-bottom: none;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px;

        .breadcrumbs {
          display: inline-flex;
          width: 100%;

          ul {
            list-style: none;
            display: flex;

            li {
              display: inline-flex;
              padding-left: 5px;
              padding-right: 5px;
              line-height: 32px;
              align-items: center;
              &.active {
                p {
                  background: #416bff33;
                  color: #8676ff;
                  cursor: default;
                }
              }
              &:first-child {
                i {
                  display: none;
                }
              }

              p {
                display: block;
                //background: #416BFF33;
                color: #384cff;
                font-size: 12px;
                line-height: 25px;
                font-weight: 600;
                cursor: pointer;
                padding-left: 10px;
                padding-right: 10px;
                border-radius: 3px;
                margin: 0;
              }

              i {
                font-size: 14px;
                color: #414d55;
              }

              span {
                display: inline-flex;
                padding-left: 10px;
                padding-right: 10px;
                padding-top: 5px;
                padding-bottom: 5px;
                color: #416bff;
                font-weight: 600;
                line-height: 22px;
                border-radius: 4px;
                background-color: #d9e1ff;
              }
            }
          }

          .checkbox {
            span {
              font-size: 12px;
              font-weight: 500;
              font-family: "Poppins", sans-serif;
              padding: 2px;
              color: #383874;
              &:first-child {
                color: #384cff;
              }
            }
          }
        }

        .btn-ellipsis {
          color: #416bff;
          border-radius: 4px;
          background-color: #ffffff;

          &:hover {
            background-color: #d9e1ff;
          }

          i {
            vertical-align: middle;
          }

          &:focus {
            outline: none;
          }

          &:focus-visible {
            outline: none;
          }
        }
      }

      .fliter-inputs {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 20px 10px 25px;

        .search-control {
          display: inline-flex;
          width: 30%;
          margin-left: 7.5px;
          margin-right: 7.5px;

          &:first-child {
            width: 40%;
          }

          .input-group-text {
            border: 1px solid #8676ff;
            font-size: 13px;
            padding-left: 10px;
            padding-right: 10px;
            color: #25252c;
            line-height: 32px;
            font-weight: 500;
            width: 100%;
            height: 32px;
            border-radius: 4px;
            box-shadow: 0px 0px 0px 4px rgba(237, 231, 253, 1);

            &:focus {
              outline: none;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .environment-container {
    .discovered-assets {
      .global-services-fliter {
        .heading {
          .breadcrumbs {
            ul {
              li {
                span {
                  font-size: 12px;
                }
              }
            }
          }
        }

        .fliter-inputs {
          width: 100%;
          display: block;

          .search-control {
            width: 100%;
            margin-left: 0;
            margin-right: 0;

            &:first-child {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .environmentlist-container {
    .discovered-assets {
      .global-services-fliter {
        .fliter-inputs {
          display: flex;
          .search-control {
            margin-left: 7.5px;
            margin-right: 7.5px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .environmentlist-container {
    .discovered-assets {
      .global-services-fliter {
        .fliter-inputs {
          width: 100%;
          display: block;

          .search-control {
            margin-left: 0;
            margin-right: 0;
          }
        }
      }
    }
  }
}
