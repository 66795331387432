.head-top-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .add-filters {
    display: flex;
    .filter-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #1b4dff;
      padding: 4px 15px;
      margin-right: 15px;
      label {
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
        color: #383874;
      }
      span {
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
        color: #383874;
      }
      .close-btn {
        padding: 2px;
        border-radius: 50%;
        border: 0.5px solid #000000;
        // .css-1vooibu-MuiSvgIcon-root{
        //   width: 0.7em;
        //   height: 0.7em;
        // }
      }
    }
  }
  .clear-filter-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid red;
    padding: 4px 15px;
    label {
      font-size: 12px;
      line-height: 20px;
      font-weight: 500;
      color: #383874;
      margin-right: 15px;
    }
    .delete-btn {
      color: red;
    }
  }
}
