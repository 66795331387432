.reports-chart-box {
  display: block;
  width: 100%;
  padding: 20px;
  min-height: 380px;
  // margin-bottom: 15px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 2.767256498336792px 2.2138051986694336px 0px #6c49ac05;
  box-shadow: 0px 6.650102138519287px 5.32008171081543px 0px #6c49ac07;
  box-shadow: 0px 12.521552085876465px 10.017241477966309px 0px #6c49ac09;
  box-shadow: 0px 22.3363094329834px 17.869047164916992px 0px #6c49ac0b;
 

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .chart-title {
     display: block;
     width: 55%;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
      .title {
        margin: 0;
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        color: #383874;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .description {
        width: 100%;
        margin: 0;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        color: #383874;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    button.primary-outline-btn {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      padding: 5px 10px;
    }
  }
  .chart-content {
    display: block;
    width: 100%;
    .chart-loader{
      height: 285px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .spend-overview-chart {
      width: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .top-used-service-chrt {
      width: 100%;
      display: block;
      .total-cost-incurred {
       display: block;
        label {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          color: #383874;
        }

        p {
          font-size: 18px;
          font-weight: 600;
          line-height: 22px;
          color: #383874;
          margin: 0;
          span {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            color: #ff2d2e;
            margin: 0;
            i {
              vertical-align: -webkit-baseline-middle;
            }
          }
        }
      }
    }
    .grouped-barplot-chart {
      width: 100%;
      display: block;
      .spending-present {
        display: inline-block;
        label {
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          color: #383874;
        }
      }
    }
    .vertical-bar-chart {
      width: 100%;
      display: block;
     .vertical-bar-chart-inner-section{
      height: 277px;
     }
    }
    .gauge-chart {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      .chart-details {
        width: 100%;
        display: block;
        // margin-top: 30px;
        // height: 165px;
        // overflow-y: auto;

        ul {
          padding: 0;

          li {
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            // margin: 0 0 5px 0;
            padding: 0;

            p {
              width: 80px;
              font-size: 12px;
              line-height: 20px;
              font-weight: 400;
              color: #383874;
              margin: 0;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .right-contant {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              width: 50%;
              span {
                display: inline-block;
                width: 100%;
                height: 6px;
                background-color: #f2f2f2;
                border-radius: 5px;
                position: relative;
                overflow: hidden;

                span {
                  display: block;
                  width: 100%;
                  border-radius: 3px;
                  position: absolute;
                  left: 0;
                  bottom: 0;

                  &.crocus-purple {
                    background: #8676ff;
                  }

                  &.orange {
                    background: #ff9066;
                  }

                  &.yellow {
                    background: #ffcc41;
                  }

                  &.paris-green {
                    background: #42cd7e;
                  }
                }
              }
            }
            label {
              width: 50px;
              font-size: 12px;
              line-height: 20px;
              font-weight: 400;
              color: #383874;
              text-align: right;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 892px) {
  .reports-chart-box {
    min-height: 0;
  }
}
@media only screen and (max-width: 1200px) {
  .reports-chart-box {
    .chart-content {
      .vertical-bar-chart {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 285px;
        .vertical-bar-chart-inner-section{
          height: 100%;
         }
      }
    }
  }
}
