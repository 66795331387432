.discovered-assets-container {
  width: 100%;
  display: block;
  padding: 30px 40px;
  background-color: #f6f7fb;
  .assets-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .global-services-fliter {
    display: block;
    width: 100%;
    border-radius: 8px;
    background-color: #ffffff;
    margin-top: 20px;
    padding: 15px 15px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
    .heading {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      color: #383874;
      span {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #1b4dff;
      }
    }
  }
  .search-box {
    margin-top: 20px;
    .search {
      display: inline-flex;
      width: 60%;
      height: 36px;
      background-color: #eef0fa;
      border-radius: 7px;
      margin-right: 15px;

      input {
        display: inline-flex;
        width: 100%;
        border: none;
        background-color: transparent;
        padding-left: 15px;
        padding-right: 15px;
        color: #000000;
        font-size: 13px;
        line-height: 36px;
      }

      button {
        display: inline-flex;
        border: none;
        cursor: pointer;
        background-color: transparent;
        color: rgb(171, 173, 216);
        padding: 6px 7px 0;
      }
    }
    .undo-icon {
      color: #a1a3d4;
    }

    .primary-btn {
      padding-left: 15px;
      padding-right: 15px;

      a {
        color: #ffffff;
      }
    }
  }
}
