.environment-container {
  width: 100%;
  display: block;
  padding: 40px 60px;
  background-color: #f6f7fb;
  .new-environment-loader {
    width: 100%;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .chart-spinner {
    min-height: 430px;
    align-items: center;
    justify-content: center;
  }

  &.environmentlist {
    padding-left: 20px;
    padding-right: 20px;
  }

  .list-heading {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    h3 {
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;
      color: #383874;
      margin-bottom: 0;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 50%;
    }

    .breadcrumbs {
      display: inline-flex;
      width: auto;
      margin-left: auto;

      ul {
        list-style: none;
        display: flex;

        li {
          display: inline-flex;
          padding-left: 3px;
          padding-right: 3px;
          line-height: 32px;
          align-items: center;
          font-size: 12px;
          color: #8676ff;
          font-weight: 400;
          &.active {
            font-weight: 600;
            cursor: auto;
            color: #414d55;
          }

          a {
            color: #8676ff;
          }

          i {
            font-size: 10px;
            color: #414d55;
          }
        }
      }
    }

    .primary-btn {
      margin-left: auto;
    }
  }

  .export-sction {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}


.table-tooltip {
  border-radius: 6px;
  .MuiTooltip-tooltip {
    margin-bottom: 0 !important;
    margin-top: 5px !important;
    border-radius: 6px;
    box-shadow: 0px 100px 80px rgba(108, 73, 172, 0.07),
      0px 41.7776px 33.4221px rgba(108, 73, 172, 0.0503198),
      0px 22.3363px 17.869px rgba(108, 73, 172, 0.0417275),
      0px 12.5216px 10.0172px rgba(108, 73, 172, 0.035),
      0px 6.6501px 5.32008px rgba(108, 73, 172, 0.0282725),
      0px 2.76726px 2.21381px rgba(108, 73, 172, 0.0196802);
      text-transform: lowercase;
      &::first-letter{
        text-transform: uppercase;
      }
  }
  .list-heading-tooltip {
    display: block;
    font-size: 11px;
    line-height: 18px;
    font-weight: 400;
    color: #383874;
    margin: 0;
  }
  .availability-inner {
    display: block;
    p {
      display: block;
      font-size: 11px;
      line-height: 18px;
      font-weight: 400;
      color: #383874;
      margin: 0 0 6px 0;
      strong {
        font-weight: 500;
      }
    }
    .heading {
      font-size: 12px;
      line-height: 16px;
      color: #383874;
      margin-left: -10px;
      margin-right: -10px;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 5px;
      border-bottom: 1px solid #eaecf0;
    }
    .location-text {
      margin-top: 8px;
      .location-add {
        font-size: 10px;
        line-height: 14px;
        color: #383874;
      }
      ul {
        display: block;
        padding: 0;
        margin: 8px 0 0 0;
        li {
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 5px;
          &:last-child {
            margin-bottom: 0;
          }
          p {
            font-size: 10px;
            line-height: 14px;
            list-style: none;
            color: #383874;
            margin: 0;
          }
          span {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #00b929;
          }
        }
      }
    }
  }
  .compliant-list {
    ul {
      display: block;
      width: 100%;
      padding: 0;
      margin: 0;

      li {
        display: block;
        list-style: none;
        text-align: left;
        border-radius: 3px;
        padding: 5px 10px 10px;
        line-height: 26px;
        font-size: 12px;
        color: #383874;
        span {
          display: inline-block;
          vertical-align: top;
          width: 60px;
          margin-right: 15px;
          box-shadow: 0px 4px 4px 0px #00000040;
          img {
            max-width: 100%;
            height: auto;
          }
        }
      }
    }
  }
  .css-kudwh-MuiTooltip-arrow::before {
    border: 1px solid #dadde9;
  }
}


