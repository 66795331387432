.setting-container {
  width: 100%;
  display: block;

  .page-header {
    padding: 30px 45px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    h3 {
      display: inline-flex;
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;
      color: #383874;
      margin-bottom: 0px;
      margin-right: 30px;
    }

    .header-title {
      display: flex;
      align-items: center;
      padding: 8px 18px;
      border: 1px solid #6418c3;
      border-radius: 4px;

      .name {
        font-weight: 600;
        color: #6418c3;
        line-height: 18px;
        font-size: 14px;
      }
    }
  }

  .tabs {
    display: block;
    width: 100%;

    .tabs-menu {
      width: 100%;
      padding-left: 60px;
      padding-right: 60px;
      padding-bottom: 0px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: #ffffff;

      li {
        padding-bottom: 16px;
        margin-right: 10px;
        margin-left: 10px;
        font-size: 13px;
        font-weight: 600;
        color: #383874;
        cursor: pointer;
        width: auto;
        display: inline-flex;
        position: relative;

        &::after {
          left: 0;
          bottom: 0;
          width: 100%;
          height: 4px;
          content: "";
          opacity: 0;
          position: absolute;
          background-color: #8676ff;
          border-top-left-radius: 50px;
          border-top-right-radius: 50px;
          box-shadow: rgba(134, 118, 255, 0.15) 0px 2px 1px,
            rgba(134, 118, 255, 0.15) 0px 4px 2px,
            rgba(134, 118, 255, 0.15) 0px 8px 4px,
            rgba(134, 118, 255, 0.15) 0px 16px 8px,
            rgba(134, 118, 255, 0.15) 0px 32px 16px;

          //box-shadow: 0px 100px 80px 0px #0f59ff5c;
        }

        &.active {
          &::after {
            opacity: 1;
          }
        }
      }
    }

    .tabs-content {
      padding: 30px 60px;
      background-color: #f6f7fb;
    }
  }

  .account-container {
    display: block;
    width: 100%;

    .head {
      display: block;
      width: 100%;
      margin-bottom: 40px;

      h4 {
        font-size: 22px;
        line-height: 24px;
        font-weight: 500;
        color: #344054;
        margin: 0;
      }

      p {
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #667085;
        margin: 10px 0 0 0;
      }
    }

    .cards {
      display: inline-block;
      width: 100%;

      .card {
        border-radius: 10px;
        background: #ffffff;
        padding: 22px;
        border: 2px solid transparent;
        min-height: 530px;

        .card-image {
          margin: 30px 0px 15px 0px;
        }

        .card-title {
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          color: #344054;
          padding-top: 10px;
        }

        .card-text {
          margin-top: 10px;
          font-size: 11px;
          font-weight: 400;
          line-height: 14px;
          color: #667085;
        }

        .card-btn {
          margin-top: 40px;

          button {
            &.disabled {
              background-color: #f2f3f7;
              color: #2e2e3a;
              font-weight: 700;
              line-height: 18px;
              font-size: 13px;
              text-transform: capitalize;
            }
          }
        }

        .password-container {
          h5 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            color: #344054;
          }

          p {
            display: flex;
            width: 100%;
            font-size: 11px;
            color: #344054;
          }

          .form-group {
            width: 100%;
            position: relative;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin-bottom: 15px;

            .form-label {
              display: flex;
              width: 100%;
              font-size: 14px;
              color: #344054;
              line-height: 20px;
              font-weight: 500;
              margin-bottom: 5px;
              padding-left: 0;
            }

            .form-control {
              display: flex;
              width: 100%;
              height: 36px;
              padding: 5px 15px 5px 15px;
              border: 1px solid #d0d5dd;
              background: linear-gradient(0deg, #ffffff, #ffffff);
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
              border-radius: 5px;
              font-size: 12px;
              line-height: 26px;
              font-weight: 400;
              color: #344054;
              font-family: "Poppins", sans-serif;

              &:focus-visible {
                outline: none;
              }
            }

            span {
              font-size: 12px;
              font-weight: 400;
              line-height: 18px;
              margin-top: 5px;
            }

            .select-policy {
              width: 100%;

              .MuiOutlinedInput-root {
                width: 100%;
                height: 40px;
                line-height: 40px;

                .MuiSelect-select {
                  width: 100%;
                  display: block;
                  font-size: 13px;
                  line-height: 40px;
                  height: 36px;
                  font-weight: 400;
                  border-color: #d0d5dd;
                  padding: 0 15px;
                  font-style: normal;

                  .MuiSelect-nativeInput {
                    height: 36px;
                  }

                  em {
                    font-style: normal;
                  }

                  &:focus-visible {
                    outline-color: transparent;
                  }

                  &:hover {
                    border-color: #d0d5dd;
                  }
                }

                .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
                  top: calc(54% - 0.5em);
                }
              }
            }

            .input-group-text {
              position: absolute;
              top: 30px;
              right: 15px;
              cursor: pointer;

              i {
                color: #dadada;
                font-size: 14px;
              }

              &.rotate {
                right: 45px;

                i {
                  color: #8676ff;
                }

                &:hover {
                  i {
                    color: #384cff;
                  }
                }
              }

              &:hover {
                i {
                  color: #344054;
                }
              }
            }
          }

          .password-strength-group {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: 20px;

            span {
              width: 25%;
              height: 6px;
              margin-left: 2px;
              margin-right: 2px;
              display: inline-flex;
              background: rgba(198, 203, 217, 1);
              border-radius: 5px;

              &.good {
                background: rgba(250, 162, 75, 1);
              }

              &.strong {
                background-color: rgba(0, 85, 0, 1);
              }
            }
          }
        }
      }
    }

    .bottom-button {
      margin-top: 30px;
    }
  }

  .permissions-container {
    display: block;
    width: 100%;

    .heading {
      display: block;
      font-size: 24px;
      line-height: 32px;
      font-weight: 400;
      color: #383874;
    }

    .access-control-boxs {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 20px 0 10px 0;
    }

    .access-control-container {
      display: block;
      width: 100%;

      .search-box {
        padding-bottom: 20px;

        .search {
          display: inline-flex;
          width: 270px;
          height: 36px;
          background-color: #eef0fa;
          border-radius: 7px;
          margin-right: 15px;

          input {
            display: inline-flex;
            width: 100%;
            border: none;
            background-color: transparent;
            padding-left: 15px;
            padding-right: 15px;
            color: #000000;
            font-size: 13px;
            line-height: 36px;
          }

          button {
            display: inline-flex;
            border: none;
            cursor: pointer;
            background-color: transparent;
            color: rgb(171, 173, 216);
            padding: 6px 7px 0;
          }
        }

        .primary-btn {
          padding-left: 15px;
          padding-right: 15px;

          a {
            color: #ffffff;
          }
        }
        .new-user {
          position: relative;
          .light-btn {
            padding-left: 15px;
            padding-right: 15px;
            font-weight: 700;
          }
          .noti {
            padding: 5px;
            position: absolute;
            top: -10px;
            right: 10px;
            width: 20px;
            height: 20px;
            background: red;
            color: #ffffff;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
          }
        }
      }

      .group-control-boxs {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .group-box {
          display: inline-flex;
          flex-wrap: wrap;
          min-height: 250px;
          width: calc(278px - 22px);
          margin-right: 20px;
          margin-bottom: 20px;
          border-radius: 15px;
          border: 1px solid #e2e2ea;
          background: #ffffff;

          &:nth-child(5n + 1) {
            margin-left: 0;
          }

          .heading {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 5px 5px 5px 15px;
            border-bottom: 1px dotted #b3b3b3;
            position: relative;

            h4 {
              font-size: 14px;
              line-height: 18px;
              font-weight: 500;
              color: #383874;
              margin: 0;
            }

            .action-btn {
              padding: 5px 5px;
              border-radius: 5px;
              color: #9a9aaf;
              margin-left: auto;
              font-size: 12px;

              &:hover {
                background-color: #d1d5ff;
              }
            }

            .action-buttons-bg {
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              position: fixed;
              z-index: 1;
              background-color: transparent;
            }

            .action-buttons {
              position: absolute;
              z-index: 10;
              right: -50px;
              top: 40px;
              width: 140px;
              overflow: hidden;
              border-radius: 10px;
              background-color: #ffffff;
              box-shadow: 0px 41.777610778808594px 33.422088623046875px 0px
                #6c49ac0d;

              .secondary-text-btn {
                font-size: 12px;
                padding: 5px 10px;
                min-width: 100%;
                text-align: left;
                border-radius: 0;
                color: #383874;
                border-bottom: 1px solid #dbdff1;

                &:last-child {
                  border-bottom: none;
                }

                .MuiButton-startIcon {
                  font-size: 12px;
                  margin-left: 0;
                  line-height: 16px;
                  vertical-align: middle;
                  margin-right: 5px;
                }
              }
            }
          }

          .group-data {
            display: flex;
            width: 100%;
            padding: 10px 15px;

            .data {
              display: inline-flex;
              flex-wrap: wrap;
              width: 50%;

              label {
                font-size: 12px;
                line-height: 16px;
                font-weight: 500;
                color: #383874;
                display: flex;
                width: 100%;
                padding-bottom: 2px;
              }

              span {
                font-size: 12px;
                line-height: 16px;
                font-weight: 600;
                color: #383874;
                display: flex;
                width: 36px;
                border-radius: 8px;
                text-align: center;
                justify-content: center;
                background-color: #f6f7fb;
                height: 24px;
                align-items: center;
              }

              .group-name {
                display: inline-flex;
                border: 1px solid #c6cbd9;
                border-radius: 70px;
                color: #383874;
                font-size: 12px;
                line-height: 16px;
                font-weight: 600;
                position: relative;
                padding: 5px 10px 5px 20px;
                width: 100%;
                align-items: center;
                background: transparent;

                &::before {
                  position: absolute;
                  left: 10px;
                  top: 8px;
                  width: 6px;
                  height: 6px;
                  content: "";
                  border-radius: 50px;
                  background-color: #8676ff;
                }
              }
            }
          }

          .description-text {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            padding: 5px 15px 10px 15px;

            label {
              font-size: 12px;
              line-height: 16px;
              font-weight: 500;
              color: #383874;
              display: flex;
              width: 100%;
              padding-bottom: 2px;
            }

            p {
              font-size: 12px;
              line-height: 16px;
              font-weight: 400;
              color: #383874;
              display: flex;
              width: 100%;
              margin: 0;
            }
          }

          .view-btn {
            display: flex;
            width: 100%;
            padding-bottom: 15px;
            justify-content: center;
            align-items: center;

            .primary-btn {
              padding-left: 15px;
              padding-right: 15px;

              a {
                color: #ffffff;
              }
            }
          }

          .roles-box {
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            background: #00b929;
            display: inline-block;
            padding: 4px 10px;
            border-radius: 4px;
            margin-left: 10px;
            color: #ffffff;

            span {
              display: flex;
              align-items: center;
            }

            img {
              margin-right: 5px;
              height: 100%;
            }
          }
        }
      }
    }
  }
}

.permission-container {
  .services-panel-tabs {
    display: block;
    width: 100%;
    .tabs-head {
      display: block;
      width: 100%;
      text-align: center;

      ul {
        display: inline-block;
        width: auto;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 8px;
        padding-right: 8px;
        border-radius: 30px;
        background-color: #dbdff1;

        li {
          width: auto;
          display: inline-block;
          vertical-align: top;
          font-size: 11px;
          line-height: 20px;
          font-weight: 500;
          color: #000000;
          padding-top: 4px;
          padding-bottom: 4px;
          padding-left: 30px;
          padding-right: 30px;
          border-radius: 30px;
          cursor: pointer;

          &.active {
            color: #ffffff;
            background-color: #3247e5;
          }
        }
      }
    }

    .permission-tabs-content {
      display: block;
      width: 100%;
      margin-top: 20px;

      .check-box {
        color: #3247e5;
      }

      .green-btn {
        color: #ffffff;
        background: #00b929;
        font-size: 12px;
        font-weight: 700;
        border: none;
        padding: 5px 10px;
        border-radius: 3px;
        text-transform: lowercase;

        &::first-letter {
          text-transform: capitalize;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .setting-container {
    .account-container {
      .cards {
        .card {
          min-height: 560px;
        }
      }
    }
  }
}

.popup-tooltip {
  ul {
    padding: 0;

    li {
      display: block;
      color: #ffffff;
      font-size: 11px;
      line-height: 14px;
      font-weight: 400;
      padding: 0;
      margin: 2px 0;
    }
  }
}
