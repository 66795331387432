.assets-table {
  display: block;
  background-color: #ffffff;
  margin-top: 25px;
  padding: 0;
  border-radius: 10px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  table {
    display: block;
    // width: 1380px;
    overflow: hidden;
    overflow-x: auto;
    thead {
      padding-top: 0px;
      padding-bottom: 0px;
      display: table;
      width: calc(100% - 0.1em)
        /* scrollbar is average 1em/16px width, remove it from thead width */;
      tr {
        display: inline-table;
        width: 100%;
        table-layout: fixed;
        th {
          padding-top: 15px;
          padding-bottom: 15px;
          padding-left: 15px;
          padding-right: 15px;
          font-size: 14px;
          font-weight: 500;
          color: #000000;
          border: none;
          font-family: "Poppins", sans-serif;
          border-bottom: 1px dashed #b3b3b3;
        }
      }
    }

    tbody {
      display: block;
      max-height: 350px;
      overflow: auto;
      width: calc(100% - 0.05em);
      tr {
        display: table;
        width: 100%;
        table-layout: fixed;

        &:last-child {
          border-bottom: none;

          td {
            border: none;

            .menu-list {
              top: auto;
              bottom: 40px;
            }
          }
        }

        td {
          padding: 12px 15px;
          font-size: 13px;
          font-weight: 500;
          color: #101828;
          position: relative;
          border-bottom: 1px solid #eaecf0;
          font-family: "Poppins", sans-serif;
          .resource-name{
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #101828;
          }
          .list-icon {
            background: transparent;
            width: 30px;
            height: 30px;
            border: none;
            color: #1b4dff;
            position: relative;
            cursor: pointer;
            border-radius: 3px;
            font-size: 16px;
            line-height: 30px;

            &:hover {
              background: #e5e1ff;
              color: #1b4dff;
            }
          }

          .menu-list {
            position: absolute;
            width: 160px;
            top: 35px;
            padding: 5px;
            z-index: 12;
            right: 47%;
            border-radius: 5px;
            background: #ffffff;
            box-shadow: -1px 2px 10px 5px rgba(0, 0, 0, 0.05);

            ul {
              display: block;
              width: 100%;
              padding: 0;
              margin: 0;

              li {
                font-size: 12px;
                font-weight: 600;
                color: #383874;
                list-style: none;
                line-height: 16px;
                padding: 5px 10px;
                border-radius: 3px;
                align-items: center;
                display: inline-flex;
                justify-content: flex-start;
                span {
                  width: 10px;
                  height: 10px;
                  margin-right: 5px;
                  border-radius: 50%;
                  background: #00b929;
                }

                &.active {
                  background: #dde1f8;
                }

                a {
                  font-size: 10px;
                  line-height: 14px;
                  font-weight: 600;
                  color: #383874;
                }
              }
            }
          }
          span {
            display: inline-block;
            text-align: center;
            line-height: 22px;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            color: #ffffff;
            &.green {
              background-color: #00b929;
            }
            &.orange {
              background-color: #ffba69;
            }
          }
          .tag {
            font-size: 20px;
            color: #00b929;
          }
          .log-eye-icon {
            font-size: 20px;
            color: #384cff;
          }
          .setting-icon {
            font-size: 20px;
            color: #9a9aaf;
          }

          &:last-child {
            overflow: unset;
            text-overflow: unset;
          }

          &:nth-child(3) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            // color: #384cff;
          }
          &:nth-child(4) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            // color: #384cff;
          }

          .list-icon {
            background: transparent;
            width: 30px;
            height: 30px;
            border: none;
            color: #1b4dff;
            position: relative;
            cursor: pointer;
            border-radius: 3px;
            font-size: 16px;
            line-height: 30px;

            &:hover {
              background: #e5e1ff;
              color: #1b4dff;
            }
          }
          a{
            color: #1b4dff;
          }
        }
      }
    }
  }
}
