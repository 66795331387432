.tier-container {
  display: block;
  width: 100%;
  margin-top: 25px;
  .topology-panel {
    display: block;
    padding: 0px;
    margin-top: 0px;
    border-radius: 10px;
    background-color: #ffffff;

    .topology-panel-title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 15px;
      border-bottom: 1px dashed #d9d9d9;
      .name {
        display: inline-flex;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color: #383874;
        margin-left: 10px;
      }
    }
    .topology-panel-body {
      height: 455px;
      display: block;
      width: 100%;
      padding: 20px 20px;
      position: relative;
      h4{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .topology-inner-content {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        margin-top: 10px;
        .content-left {
          ul {
            display: block;
            padding: 0;
            li {
              padding: 0;
              margin-bottom: 45px;
              &:last-child {
                margin-bottom: 0px;
              }
              .button-box {
                display: inline-flex;
                width: 130px;
                cursor: pointer;
                border: 1px solid #8676ff;
                border-radius: 5px;
                padding: 6px 10px;
                margin-right: 20px;
                span {
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  width: 24px;
                  height: 24px;
                  min-width: 24px;
                  padding: 5px;
                  margin-right: 5px;
                  border-radius: 50%;
                  background-color: #f4f4f4;
                }
                p {
                  display: inline-flex;
                  font-size: 12px;
                  line-height: 24px;
                  font-weight: 600;
                  white-space: nowrap;
                  margin: 0;
                  color: #383874;
                }
              }
              span {
                width: 30px;
                height: 30px;
                img {
                  max-width: 100%;
                }
              }
            }
          }
        }
        .content-middle {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;

          ul {
            display: block;
            padding: 0;
            margin: 0 15px 0;
            li {
              display: flex;
              align-items: baseline;
              justify-content: flex-start;
              margin: 0;
              padding: 0;

              .application-balancer {
                display: flex;
                width: 100%;
                justify-content: center;
                flex-wrap: wrap;
                .secondary-btn {
                  background-color: #dde1f8;
                  color: #383874;
                  border: 1px solid #3247e5;
                  i {
                    margin-left: 15px;
                  }
                }
                .balancer-boxs {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-wrap: wrap;
                  margin-top: 10px;
                  margin-bottom: 10px;
                  .balancer-box {
                    width: 36px;
                    margin-left: 15px;
                    margin-right: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                    span {
                      display: flex;
                      justify-content: center;
                      width: 100%;
                    }
                    .icon {
                      display: inline-flex;
                      width: 36px;
                      height: 36px;
                      align-items: center;
                      justify-content: center;
                      border-radius: 5px;
                      padding: 5px;
                      margin-top: 7px;
                      margin-bottom: 7px;
                      cursor: pointer;
                      background: linear-gradient(
                        45deg,
                        #c8511b -4950%,
                        #ff9900 5050%
                      );
                      img {
                        max-width: 20px;
                      }
                      &.active {
                        background: #3247e5;
                      }
                    }
                    p {
                      display: flex;
                      width: 100%;
                      justify-content: center;
                      text-align: center;
                      margin: 0;
                      font-size: 10px;
                      line-height: 14px;
                      font-weight: 600;
                      color: #383874;
                    }
                  }
                }
              }
              .mapping-fliter {
                display: flex;
                width: 100%;
                justify-content: center;
                flex-wrap: wrap;
                position: relative;
                .fliter-toggel {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  border: none;
                  background: #dde1f8;
                  color: #383874;
                  font-size: 12px;
                  font-weight: 600;
                  height: 36px;
                  padding: 0 15px;
                  border-radius: 4px;
                  cursor: pointer;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;

                  .arrow-icon {
                    margin-left: 20px;
                  }
                }

                .fliter-collapse {
                  background-color: #ffffff;
                  border-radius: 5px;
                  padding: 3px;
                  display: none;
                  position: absolute;
                  top: 38px;
                  width: 172px;
                  left: auto;
                  right: auto;
                  z-index: 10;
                  overflow: hidden;
                  overflow-y: auto;
                  max-height: 200px;
                  box-shadow: 0px 4px 32px 0px #00103d29;

                  &.active {
                    display: block;
                  }

                  ul {
                    display: block;
                    list-style: none;
                    padding: 0;
                    margin: 0;

                    li {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: flex-start;
                      padding: 0;
                      margin-bottom: 0;
                      color: #383874;
                      font-size: 12px;
                      line-height: 16px;
                      font-weight: 500;
                      padding: 7px 10px;
                      border-radius: 3px;
                      cursor: pointer;
                      p {
                        color: #383874;
                        font-size: 12px;
                        line-height: 16px;
                        font-weight: 500;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin: 0;
                      }
                      span {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      }
                      &.disabled {
                        cursor: auto;
                        background-color: transparent;
                        i{
                          color: rgba(0, 0, 0, 0.26);
                        }
                        p{
                          color: rgba(0, 0, 0, 0.26);
                        }
                    }
                      &:hover {
                        color: #383874;
                        background-color: #dde1f8;
                      }
                      &.active {
                        color: #383874;
                        background-color: #dde1f8;
                      }

                      i {
                        margin-right: 7px;
                      }
                    }
                  }
                }

                .fliters-collapse-bg {
                  position: fixed;
                  width: 100%;
                  height: 100%;
                  left: 0;
                  top: 0;
                  z-index: 1;
                  display: none;

                  &.active {
                    display: block;
                  }
                }
              }
              &.active {
                .secondary-btn {
                  color: #ffffff;
                  background: #3247e5;
                }
                .mapping-fliter {
                  .fliter-toggel {
                    color: #ffffff;
                    background: #3247e5;
                  }
                }
              }
            }
          }
          .check-icons-box {
            display: block;
            margin-right: 25px;
            ul {
              display: block;
              padding: 0;
              margin: 0;
              li {
                margin: 55px 0 0 0;
                padding: 0;
                .check-icon {
                  background: #00b929;
                  margin-right: 15px;
                  i {
                    font-size: 16px;
                    color: #ffffff;
                  }
                }
                .edit-icon {
                  background: #3247e5;
                  i {
                    font-size: 16px;
                    color: #ffffff;
                  }
                }
                .delete-icons{
                 height: 32px;
                }

                &:first-child {
                  margin-top: 85px;
                }
              }
            }
          }
        }
      }
      .topology-loder{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }

      .gmnoprint {
        position: absolute;
        bottom: 0px;
        right: 0px;
        width: 30px;
        z-index: 1;

        .gmnoprint-plus-minus {
          display: block;
          width: 100%;
          margin-bottom: 10px;
          box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04);

          .btn {
            display: block;
            color: #023aff;
            font-size: 14px;
            line-height: 30px;
            width: 30px;
            height: 30px;
            border: none;
            cursor: pointer;
            padding: 0;
            text-align: center;
            background-color: #ffffff;
          }
        }
      }

      .topology-inner-body {
        display: flex;
        width: 100%;
        margin-top: auto;
        margin-bottom: auto;
      }
      .failover-graph {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 20%;
        li {
          width: 100%;
          padding-bottom: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          flex-wrap: wrap;
          .primary-btn {
            width: 100%;
          }
          span {
            margin-top: 5px;
            img {
              height: 50px;
            }
          }
          &.start {
            width: auto;
            .primary-btn {
              border-radius: 30px;
              padding-left: 45px;
              padding-right: 45px;
              color: #383874;
              background-color: #dde1f8;
            }
          }
          &.end {
            width: auto;
            .primary-btn {
              border-radius: 30px;
              padding-left: 45px;
              padding-right: 45px;
              background-color: #00b929;
            }
          }
          &.red {
            .primary-btn {
              background-color: #ff2d2e;
            }
          }
          &.disabled {
            .primary-btn {
              color: rgb(192 192 195);
              background-color: rgb(202 213 255);
            }
          }
        }
      }
    }
  }
  .nginx-cards {
    background: #ffffff;
    border-radius: 5px;
    display: block;
    height: 455px;
    overflow: hidden;
    // overflow-x: auto;
    width: 100%;
    padding: 25px 20px;
    .deployed-content {
      display: block;
      width: 100%;
    }
  }
  .nginx-section {
    background: #ffffff;
    border-radius: 10px;
    display: block;
    overflow: hidden;
    overflow-x: auto;
    margin-top: 25px;
    width: 100%;
    box-shadow: 0px 41.7776px 33.4221px rgba(108, 73, 172, 0.0503198);

    .tabs {
      display: block;
      width: 100%;

      .tabs-menu {
        width: 100%;
        padding-bottom: 0px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-color: #ffffff;
        border-bottom: 1px solid #b3b3b3;

        li {
          padding-bottom: 16px;
          font-size: 13px;
          font-weight: 600;
          color: #383874;
          cursor: pointer;
          width: auto;
          display: inline-flex;
          position: relative;

          &::after {
            left: 0;
            bottom: 0;
            width: 100%;
            height: 4px;
            content: "";
            opacity: 0;
            position: absolute;
            background-color: #8676ff;
            border-top-left-radius: 50px;
            border-top-right-radius: 50px;
            box-shadow: rgba(134, 118, 255, 0.15) 0px 2px 1px,
              rgba(134, 118, 255, 0.15) 0px 4px 2px,
              rgba(134, 118, 255, 0.15) 0px 8px 4px,
              rgba(134, 118, 255, 0.15) 0px 16px 8px,
              rgba(134, 118, 255, 0.15) 0px 32px 16px;
          }

          &.active {
            &::after {
              opacity: 1;
            }
          }
        }
      }

      .tabs-content {
        padding: 0 10px;
        margin: 0;
        .ports-content {
          width: 100%;
          display: block;
          margin-top: 20px;
          .ports-inner-content {
            display: block;
            width: 100%;
            .ports-public-section {
              width: 100%;
              display: inline-flex;
              align-items: center;
              ul {
                display: block;
                padding: 0 30px 20px;
                margin: 0;
                align-items: flex-end;
                justify-content: flex-start;

                li {
                  display: block;
                  padding: 0;
                  margin: 0;
                  width: auto;
                  text-align: center;
                  z-index: 1;
                  .ports-public {
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    flex-wrap: wrap;
                    .public-ip {
                      width: 200px;
                      background: #ffba69;
                      border-radius: 5px;
                      height: 35px;
                      color: #ffffff;
                      font-size: 14px;
                      line-height: 35px;
                      font-weight: 400;
                      text-align: center;
                      &.green {
                        background: #53ca43;
                      }
                      &.blue {
                        background: #3697ef;
                        width: auto;
                        padding: 0 20px;
                      }
                    }
                    .balancer-boxs {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      flex-wrap: wrap;
                      margin-bottom: 10px;
                      z-index: 1;
                      .balancer-box {
                        width: 36px;
                        margin-left: 15px;
                        margin-right: 15px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-wrap: wrap;
                        .green {
                          color: #53ca43;
                        }
                        .blue {
                          color: #1b4dff;
                        }
                        .orange {
                          color: #ffba69;
                        }
                        &:nth-child(1) {
                          .icon {
                            border: 1px solid #53ca43;
                          }
                        }
                        &:nth-child(2) {
                          .icon {
                            border: 1px solid #1b4dff;
                          }
                        }
                        &:nth-child(3) {
                          .icon {
                            border: 1px solid #ffba69;
                          }
                        }
                        span {
                          display: flex;
                          justify-content: center;
                          width: 100%;
                        }

                        p {
                          display: flex;
                          width: 100%;
                          justify-content: center;
                          text-align: center;
                          margin: 0;
                          font-size: 12px;
                          line-height: 16px;
                          font-weight: 600;
                          color: #383874;
                        }
                      }
                    }
                  }
                  .cluster-box {
                    ul {
                      padding: 0;
                      margin: 0;
                      position: relative;
                      &::after {
                        content: "";
                        border: 1px dashed #000000;
                        position: absolute;
                        top: 18px;
                        left: 0;
                        right: 0;
                        width: 40%;
                        height: 50%;
                        border-radius: 8px;
                        margin: 0 auto;
                      }
                      li {
                        .public-ip {
                          width: auto;
                          padding: 0 20px;
                          background: #ffba69;
                          border-radius: 5px;
                          height: 35px;
                          color: #ffffff;
                          font-size: 14px;
                          line-height: 35px;
                          font-weight: 400;
                          text-align: center;
                          &.green {
                            background: #53ca43;
                          }
                          &.blue {
                            background: #3697ef;
                          }
                        }
                        .balancer-boxs {
                          width: 100%;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          flex-wrap: wrap;
                          margin-bottom: 10px;
                          z-index: 1;
                          .balancer-box {
                            width: 36px;
                            margin-left: 0;
                            margin-right: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-wrap: wrap;
                          }
                        }
                      }
                    }
                  }
                  .ingress-boxs {
                    display: inline-flex;
                    flex-wrap: wrap;
                    flex-wrap: wrap;
                    width: 270px;
                    background: #b9bec6;
                    padding: 10px 15px;
                    border-radius: 5px;
                    margin-top: 20px;
                    .ingress-box {
                      width: 30px;
                      min-width: 30px;
                      height: 30px;
                      margin: 5px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      background: #ffffff;
                      border-radius: 3px;
                    }
                  }
                }
              }
            }
            .following-section {
              display: flex;
              flex-wrap: wrap;
              .following-ports {
                padding: 15px;
                border-radius: 5px;
                background: #ffba69;
                width: 360px;
                margin-right: 20px;
                margin-bottom: 20px;
                &.alpha-cluster {
                  background: #faa24b;
                }
                &.load-balancer {
                  background: #88e143;
                }
                &.omega-ingress {
                  background: #3697ef;
                }

                ul {
                  display: block;
                  margin: 10px 0 0 0;
                  padding: 0;
                  li {
                    display: block;
                    font-size: 13px;
                    line-height: 18px;
                    font-weight: 400;
                    color: #000000;
                    padding: 0;
                    margin-bottom: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .table-buttom-section{
    position: relative;
    .primary-btn{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
@media (max-width: 1280px) {
  .tier-container {
    .nginx-section {
      .tabs {
        .tabs-content {
          .ports-content {
            width: 100%;
            display: block;
            margin-top: 20px;
            overflow: hidden;
            overflow-x: auto;
            .ports-inner-content {
              width: 1200px;
              overflow: hidden;
              overflow-x: auto;
            }
          }
        }
      }
    }
    .topology-panel {
      .topology-panel-body {
        .topology-inner-content {
          width: 100%;
          overflow: auto;
          padding: 15px 0;
          .content-middle {
            ul {
              display: block;
              padding: 0;
            }
          }
        }
      }
    }
  }
}
