.environment-container {
  .services-panel-tabs {
    display: block;
    width: 100%;
    margin-top: 25px;

    .tabs-head {
      display: block;
      width: 100%;
      text-align: center;

      ul {
        display: inline-block;
        width: auto;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 8px;
        padding-right: 8px;
        border-radius: 30px;
        background-color: #dbdff1;

        li {
          width: auto;
          display: inline-block;
          vertical-align: top;
          font-size: 11px;
          line-height: 20px;
          font-weight: 500;
          color: #000000;
          padding-top: 4px;
          padding-bottom: 4px;
          padding-left: 20px;
          padding-right: 20px;
          border-radius: 30px;
          cursor: pointer;

          &.active {
            color: #ffffff;
            background-color: #3247e5;
          }
        }
      }
    }

    .tabs-content {
      display: block;
      width: 100%;
      margin-top: 25px;

      .cloud-all-services {
        .cloud-managed-cards {
          max-height: 358px !important;
          height: 358px;
          .cloud-managed-cards-scroll {
            .service-card {
              padding: 27px 10px !important;
            }
          }
        }
      }

      .discovered-assets {
        .discovered-loading {
          display: flex;
          align-items: center;
          justify-content: center;
          i {
            margin-right: 8px;
          }
        }
        .discovered-assets-head {
          display: block;
          .radio-group {
            padding: 8px 10px;
            border-radius: 3px;
            border: 1px solid #384cff;
            .checkbox {
              margin-right: 15px;

              &:last-child {
                margin-right: 0;
              }
              label {
                font-size: 12px;
                line-height: 15px;
                font-weight: 400;
                color: #000000;
              }
              .checkbox-input {
                margin-top: 0;
                accent-color: #384cff;
              }
            }
          }
        }

        .discovered-assets-body {
          position: relative;

          .tier-buttons {
            position: absolute;
            right: 0;
            top: -45px;

            button {
              border-width: 1px;
              border-style: solid;
              border-color: #384cff;
              padding-top: 1px;
              padding-bottom: 1px;
              &:first-child {
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                border-right: none;
              }
              &:last-child {
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                border-left: none;
              }
            }
          }

          .discovered-loading {
            display: flex;
            align-items: center;
            justify-content: center;
            i {
              margin-right: 8px;
            }
          }
          .global-service-penal {
            .cluster-heading {
              font-weight: 500;
            }

            .global-services-fliter {
              display: block;
              width: 100%;
              border-radius: 8px;
              background-color: #ffffff;
              margin-top: 20px;
              box-shadow: 0px 1px 2px 0px rgba(65, 107, 255, 0.04);

              .heading {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 15px;
                border: none;

                .breadcrumbs {
                  display: inline-flex;

                  ul {
                    list-style: none;
                    display: flex;
                    padding: 0;

                    li {
                      display: inline-flex;
                      padding-top: 0;
                      padding-bottom: 0;
                      padding-left: 3px;
                      padding-right: 3px;
                      line-height: 32px;
                      align-items: center;
                      white-space: nowrap;

                      a {
                        color: #3b7cff;
                        font-size: 12px;
                      }

                      i {
                        font-size: 14px;
                        color: #414d55;
                      }

                      span {
                        display: inline-flex;
                        padding-left: 10px;
                        padding-right: 10px;
                        padding-top: 3px;
                        font-size: 12px;
                        padding-bottom: 3px;
                        color: #416bff;
                        font-weight: 600;
                        line-height: 22px;
                        border-radius: 4px;
                        background-color: #d9e1ff;
                      }
                    }
                  }
                }
              }
            }

            .cloud-managed-section {
              h4 {
                margin-bottom: 18px;
              }
              .cloud-managed-tab {
                width: 100%;
                display: block;
                background: #ffffff;
                padding: 10px 5px;
                border-radius: 5px;
                height: 362px;
                overflow: hidden;
                overflow-y: auto;

                ul {
                  width: 100%;
                  display: block;
                  padding: 0;
                  margin: 0;

                  li {
                    display: block;
                    list-style: none;
                    text-align: center;
                    padding: 8px 5px;
                    margin-bottom: 10px;
                    cursor: pointer;
                    font-weight: 500;
                    font-size: 14px;
                    color: #535362;

                    &:hover {
                      border-radius: 3px;
                      background: #e8eaff;
                    }

                    &.active {
                      border-radius: 3px;
                      background: #dde1f8;
                      color: #383874;

                      a {
                        color: #383874;
                      }
                    }

                    a {
                      font-size: 12px;
                      line-height: 16px;
                      font-weight: 600;
                      color: #000000;
                    }
                  }
                }
              }

              .cloud-managed-cards {
                background: #ffffff;
                padding: 10px 0;
                border-radius: 8px;
                display: block;
                overflow: hidden;
                overflow-x: auto;
                overflow-y: auto;
                width: 100%;
                height: 362px;
                .data-available {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 320px;
                }
                &.global-sarvices-card {
                  height: 415px;
                }

                .cloud-managed-cards-scroll {
                  display: flex;
                  flex-wrap: wrap;
                  width: 696px;
                  margin: 10px;
                }
              }

              .data-cloud-managed {
                .cloud-managed-cards {
                  .cloud-managed-cards-scroll {
                    width: 510px;
                  }
                }
              }
            }

            .global-service-cards {
              display: flex;
              width: 100%;
              overflow: hidden;
              overflow-x: auto;
              margin-top: 20px;
              padding-bottom: 10px;
            }

            .resources-section {
              width: 100%;
              display: block;

              .account-list-conitant {
                display: block;
                overflow: hidden;
                overflow-x: auto;
                width: 100%;
                height: 192px;

                .account-list-conitant-scroll {
                  display: flex;
                  flex-wrap: wrap;
                  width: 600px;
                }

                .account-list-details {
                  width: 90px;
                  background: #ffffff;
                  // display: inline-block;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin: 0 5px 10px 5px;
                  padding: 10px 10px;
                  border-radius: 10px;
                  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04);

                  strong {
                    width: 100%;
                    display: block;
                    text-align: center;
                    font-size: 22px;
                    line-height: 26px;
                    color: #383874;
                  }

                  p {
                    width: 100%;
                    display: block;
                    text-align: center;
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 600;
                    color: #383874;
                    margin: 0;
                  }
                }
              }
            }

            .performance-section {
              .performance-head {
                margin: 15px 0 0 0;

                h4 {
                  width: 100%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;

                  .head-right {
                    float: right;
                  }
                }

                button {
                  min-width: inherit !important;
                }
              }
            }
          }
        }

        .recently-viewed-section {
          width: 100%;
          display: block;
          margin-top: 30px;

          h3 {
            margin-bottom: 20px;
          }

          .recently-cards {
            display: flex;
            flex-wrap: wrap;

            .recently-card {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04);
              padding: 15px 10px;
              cursor: pointer;
              min-height: 120px;
              margin-bottom: 10px;
              border-radius: 10px;
              width: 220px;
              margin-right: 20px;
              background: #ffffff;
              position: relative;

              .recently-image {
                display: flex;
                width: 48px;
                height: 48px;
                border-radius: 50%;
                background: #f6f7fb;
                align-items: center;
                justify-content: center;

                img {
                  max-width: 60%;
                }
              }

              .recently-content {
                margin-left: 10px;

                .title {
                  font-size: 14px;
                  line-height: 16px;
                  font-weight: 600;
                  color: #383874;
                }

                .refund-content {
                  display: flex;
                  margin-top: 5px;

                  span {
                    font-size: 9px;
                    line-height: 12px;
                    font-weight: 500;
                    color: #383874;
                    margin-right: 10px;
                    padding: 5px 10px;
                    border-radius: 5px;
                    background: #f6f7fb;

                    &:last-child {
                      margin-right: 0;
                    }
                  }
                }
              }

              .favorites-check {
                position: absolute;
                top: 10px;
                right: 10px;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                text-align: center;
                line-height: 18px;
                color: #c9cedb;
                font-size: 10px;
                border: 1px solid #c9cedb;
                cursor: pointer;
              }
              .favorites-star {
                position: absolute;
                top: 10px;
                right: 10px;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                text-align: center;
                line-height: 20px;
                color: #ffffff;
                font-size: 10px;
                background: #ffba69;
                cursor: pointer;
              }
            }
          }
        }

        .logistics-cards {
          width: 100%;
          display: flex;
          margin-top: 20px;
          flex-wrap: wrap;

          .logistics-card {
            display: inline-flex;
            align-items: flex-start;
            justify-content: flex-start;
            box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04);
            padding: 15px 10px;
            cursor: pointer;
            min-height: 120px;
            margin-bottom: 20px;
            border-radius: 10px;
            width: 295px;
            margin-left: 20px;
            background: #ffffff;
            position: relative;
            flex-wrap: wrap;

            &:nth-of-type(4n + 1) {
              margin-left: 0px;
            }

            .heading {
              display: flex;
              width: 100%;
              justify-content: center;
              font-weight: 600;
              font-size: 14px;
              line-height: 18px;
              color: #384cff;
              margin-bottom: 15px;
            }

            .contents {
              display: flex;
              width: 100%;
              .tier-buttons {
                display: flex;
                width: 100%;
                justify-content: center;
                button {
                  display: inline-flex;
                  width: 25%;
                  padding: 8px 0px;
                  border-radius: 0px;
                  border-bottom: 1px solid #ddd;
                  position: relative;
                  font-size: 12px;
                  line-height: 16px;
                  color: #383874;
                  font-weight: 600;
                  text-transform: none;
                  font-family: "Poppins", sans-serif;
                  &.active {
                    &::before {
                      position: absolute;
                      left: 0;
                      bottom: -1px;
                      content: "";
                      width: 100%;
                      height: 3px;
                      background-color: #8676ff;
                      border-top-left-radius: 15px;
                      border-top-right-radius: 15px;
                    }
                  }
                }
              }
              .tier-contents {
                display: flex;
                width: 100%;
                margin-top: 20px;
                .overview {
                  tr {
                    th {
                      padding: 0 0 8px 0;
                      font-size: 12px;
                      line-height: 16px;
                      font-weight: 600;
                      color: #000000;
                      font-family: "Poppins", sans-serif;
                      border-bottom: none;
                    }
                    td {
                      padding: 0 0 8px 0;
                      font-size: 12px;
                      line-height: 16px;
                      font-weight: 400;
                      color: #000000;
                      font-family: "Poppins", sans-serif;
                      border-bottom: none;
                      .active {
                        color: #53ca43;
                      }
                    }
                    &:last-child {
                      td {
                        padding-bottom: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .tiersoc-boxs {
          display: -webkit-box;
          width: 100%;
          // margin-top: 25px;
          overflow: hidden;
          overflow-x: auto;

          .tiersoc-box {
            border-radius: 10px;
            background-color: #ffffff;
            box-shadow: 0px 100px 80px 0px #6c49ac12;
            // width: calc(20.2222% - 15px);
            width: 236px;
            margin-right: 15px;
            margin-bottom: 15px;
            &:last-child {
              margin-right: 0;
            }

            // &:nth-child(5n + 5) {
            //   margin-right: 0;
            // }

            .heading {
              display: flex;
              width: 100%;
              padding: 8px 10px;
              justify-content: space-between;
              align-items: center;
              border-bottom: 1px solid #ececec;
              h3 {
                display: inline-flex;
                align-items: center;
                font-size: 12px;
                line-height: 16px;
                font-weight: 600;
                color: #383874;
                margin-bottom: 0;

                p {
                  display: inline-flex;
                  width: 16px;
                  height: 16px;
                  padding: 3px;
                  margin: 0;
                  margin-right: 5px;
                  border-radius: 50%;
                  background-color: #f6f7fb;

                  img {
                    max-width: 100%;
                    height: auto;
                  }
                }
                span {
                  width: 110px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
              a {
                padding: 3px 5px;
                font-size: 11px;
                font-weight: 600;
                color: #384cff;
                text-transform: none;
                background-color: transparent;
              }
              .Mui-disabled {
                color: rgba(0, 0, 0, 0.26);
              }
             
            }

            .contents {
              display: flex;
              width: 100%;
              padding: 10px 15px;
              flex-wrap: wrap;
              min-height: 175px;
              align-items: flex-start;

              .tier-buttons {
                display: flex;
                width: 100%;
                justify-content: center;
                margin-bottom: 10px;
                button {
                  padding: 5px 10px;
                  font-size: 12px;
                  line-height: 18px;
                  color: #383874;
                  font-weight: 600;
                  border-radius: 0;
                  border-bottom: 1px solid #c6cbd9;
                  text-transform: none;

                  &.active {
                    border-bottom: 3px solid #8676ff;
                  }
                }
              }

              .tier-contents {
                display: flex;
                width: 100%;
                ul {
                  list-style: none;
                  width: 100%;
                  display: block;
                  li {
                    display: flex;
                    font-size: 12px;
                    line-height: 20px;
                    font-weight: 500;
                    color: #383874;
                    margin-bottom: 8px;

                    &:nth-child(1) {
                      span {
                        background-color: #fafbf6;
                      }
                    }

                    &:nth-child(2) {
                      span {
                        background-color: #fbf6f6;
                      }
                    }

                    &:nth-child(3) {
                      span {
                        background-color: #f6fbf8;
                      }
                    }

                    &:nth-child(4) {
                      span {
                        background-color: #f6fbf8;
                      }
                    }

                    span {
                      display: inline-flex;
                      vertical-align: top;
                      width: 20px;
                      height: 20px;
                      padding: 5px;
                      text-align: center;
                      border-radius: 50%;
                      margin-right: 5px;

                      img {
                        vertical-align: middle;
                      }
                    }
                    label {
                      display: inline-flex;
                    }
                    strong {
                      font-size: 14px;
                      font-weight: 600;
                      margin-left: auto;
                    }
                  }
                }
              }
            }

            .buttons {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-left: 15px;
              padding-right: 15px;
              padding-bottom: 15px;

              .button {
                padding-left: 10px;
                padding-right: 10px;
                padding-top: 7px;
                padding-bottom: 7px;
                font-size: 12px;
                line-height: 16px;
                p {
                  width: 80px;
                  margin: 0;
                  font-size: 12px;
                  font-weight: 600;
                  font-family: "Open Sans", sans-serif;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              a {
                padding-left: 10px;
                padding-right: 10px;
                padding-top: 3px;
                padding-bottom: 3px;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
  .lambda-functions-container {
    width: 100%;
    display: block;
    // padding: 20px 0;
    margin-top: 25px;

    .heading {
      display: block;
      width: 100%;
      padding: 0 0 10px 0;

      h3 {
        display: block;
        color: #000000;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 0;
      }
    }

    .lambda-functions-table-section {
      display: block;
      width: 100%;
      h3 {
        text-align: center;
      }

      .lambda-functions-table {
        overflow: hidden;
        border-radius: 7px;
        background-color: #ffffff;
        tr {
          th {
            border-right: 1px solid #dbdff1;
            background-color: #f0f3ff;
            font-size: 13px;
            font-weight: 600;
            padding: 10px;
            &:last-child {
              border-right: none;
            }
          }
          td {
            font-size: 13px;
            padding: 12px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            &.functionid {
              max-width: 165px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              .link-btn {
                max-width: 165px;

                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                padding: 0;
                text-transform: capitalize;
                color: #384cff;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                p {
                  margin: 0;
                  font-size: 13px;
                  color: #384cff;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                }
                &:hover {
                  background-color: transparent;
                }
              }
              .Mui-disabled {
                background-color: transparent;
                p {
                  margin: 0;
                  font-size: 13px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  color: rgba(0, 0, 0, 0.87);
                }
              }
            }
            &.productid {
              max-width: 90px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          &:last-child {
            td {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .environment-container {
    .services-panel-tabs {
      .tabs-head {
        ul {
          li {
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      }
      .tabs-content {
        .discovered-assets {
          .discovered-assets-body {
            .tier-buttons {
              button {
                padding-left: 5px;
                padding-right: 5px;
                font-size: 11px;
              }
            }
          }
          .logistics-cards {
            .logistics-card {
              width: 284px;
              .top-content .image {
                height: 70px;
              }
              &:nth-child(4) {
                margin-right: 20px;
              }

              &:nth-child(8) {
                margin-right: 20px;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1280px) {
  .environment-container {
    .services-panel-tabs {
      .tabs-head {
        .tabs-break-width {
          display: block;
          text-align: left;
        }
        .tabs-menu {
          display: inline-flex;
          > div {
            padding: 0;
            font-size: 11px;
            line-height: 28px;
            padding-left: 15px;
            padding-right: 25px;
            color: #384cff;
          }
          > svg {
            right: 2px !important;
            color: #384cff;
          }
          > fieldset {
            border-color: #384cff;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 992px) {
  .environment-container {
    .services-panel-tabs {
      .tabs-head {
        .tabs-break-width {
          display: block;
          text-align: left;
          width: 100%;
        }
        .tabs-menu {
          display: inline-flex;
          > div {
            padding: 0;
            font-size: 11px;
            line-height: 28px;
            padding-left: 15px;
            padding-right: 25px;
            color: #384cff;
          }
          > svg {
            right: 2px !important;
            color: #384cff;
          }
          > fieldset {
            border-color: #384cff;
          }
        }
      }
    }
  }
}
