.sidebar {
  position: fixed;
  z-index: 11;
  top: 50px;
  left: 0;
  width: 70px;
  height: calc(100% - 50px);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #ffffff;
  box-shadow: 0 5px 7px -1px rgba(0, 0, 0, 0.15);
  transition: width 0.2s;
  flex: 1;
  flex-direction: column;
    

  &.open {
    width: 200px;

    .sidebar-inner {
      overflow: hidden;
      overflow-y: scroll;
      > ul {
        > li {
          .name {
            display: inline-flex;
          }
        }
      }
    }
  }

  &.close {
    .sidebar-inner {
      > ul {
        flex-direction: column;
        > li {
          > a {
            .name {
              display: none;
              position: absolute;
              left: 50px;
              top: 7px;
              font-size: 11px;
              line-height: 16px;
              font-weight: 400;
              color: #ffffff;
              border-radius: 5px;
              padding: 5px 10px;
              background-color: #384cff;
            }
            button {
              display: none;
            }
          }
          > ul {
            display: none;
            position: absolute;
            z-index: 10;
            top: 30px;
            left: 30px;
            width: 180px;
            padding: 10px;
            border-radius: 5px;
            background-color: #ffffff;
            box-shadow: 0px 100px 80px rgba(108, 73, 172, 0.07),
              0px 41.7776px 33.4221px rgba(108, 73, 172, 0.0503198),
              0px 22.3363px 17.869px rgba(108, 73, 172, 0.0417275),
              0px 12.5216px 10.0172px rgba(108, 73, 172, 0.035),
              0px 6.6501px 5.32008px rgba(108, 73, 172, 0.0282725),
              0px 2.76726px 2.21381px rgba(108, 73, 172, 0.0196802);
            > li {
              display: block;
              margin-bottom: 2px;
              a {
                font-size: 12px;
                line-height: 16px;
                color: #383874;
                span {
                  margin-top: 2px;
                }
              }
            }
          }
          &:hover {
            a {
              background-color: #dbdff1;
              .icon {
                &.dashboard {
                  background-image: url("../../img/sidebar/dashboard-h.png");
                }
                &.asset {
                  background-image: url("../../img/sidebar/asset-h.png");
                }
                &.setting {
                  background-image: url("../../img/sidebar/setting-h.png");
                }
                &.alert {
                  background-image: url("../../img/sidebar/alert-h.png");
                }
                &.appcatalogue {
                  background-image: url("../../img/sidebar/appcatalogue-h.png");
                }
                &.preferences {
                  background-image: url("../../img/sidebar/preferences-h.png");
                }
                &.bimapping{
                  background-image: url("../../img//sidebar/bimapping-h.png");
                }
                &.report {
                  background-image: url("../../img/sidebar/reporting-h.png");
                }
              }
              .name {
                display: inline-flex;
                color: #ffffff;
              }
            }
            ul {
              display: block;
              li {
                a {
                  background-color: #ffffff;
                  &:hover {
                    background-color: #dbdff1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .open-close {
    position: absolute;
    z-index: 10;
    cursor: pointer;
    right: 0;
    margin-right: -13px;
    top: 5px;
    background-color: #ffffff;
    box-shadow: 0px 100px 80px rgba(108, 73, 172, 0.07),
      0px 41.7776px 33.4221px rgba(108, 73, 172, 0.0503198),
      0px 22.3363px 17.869px rgba(108, 73, 172, 0.0417275),
      0px 12.5216px 10.0172px rgba(108, 73, 172, 0.035),
      0px 6.6501px 5.32008px rgba(108, 73, 172, 0.0282725),
      0px 2.76726px 2.21381px rgba(108, 73, 172, 0.0196802);
    &:hover {
      background-color: #ffffff;
    }
  }

  .sidebar-inner {
    height: 100%;
    width: 100%;
    > ul {
      display: flex;
      width: 100%;
      list-style: none;
      flex-wrap: wrap;
      padding: 30px 0 0 0;
      margin: 0;
      flex: 1;
      flex-direction: column;

      > li {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        cursor: pointer;
        margin-top: 12px;
        margin-bottom: 12px;
        position: relative;
        padding-left: 10px;
        padding-right: 14px;
        padding-top: 0;
        padding-bottom: 0;

        > a {
          padding-left: 10px;
          padding-right: 10px;
          padding-top: 8px;
          padding-bottom: 8px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          height: auto;
          border-radius: 5px;

          .icon {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 24px;
            height: 24px;
            display: inline-flex;

            &.dashboard {
              background-image: url("../../img/sidebar/dashboard.png");
            }
            &.asset {
              background-image: url("../../img/sidebar/asset.png");
            }
            &.setting {
              background-image: url("../../img/sidebar/setting.png");
            }
            &.alert {
              background-image: url("../../img/sidebar/alert.png");
            }
            &.appcatalogue {
              background-image: url("../../img/sidebar/appcatalogue.png");
            }
            &.preferences {
              background-image: url("../../img/sidebar/preferences.png");
            }
            &.bimapping{
              background-image: url("../../img//sidebar/bimapping.png");
            }
            &.report{
              background-image: url("../../img//sidebar/reporting.png");
            }
          }

          .name {
            display: none;
            margin-left: 10px;
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            color: #b8b4cc;
            white-space: nowrap;
          }

          button {
            margin-left: auto;
          }

          &:hover {
            background-color: #dbdff1;

            .name {
              color: #1b4dffed;
            }

            .icon {
              &.dashboard {
                background-image: url("../../img/sidebar/dashboard-h.png");
              }
              &.asset {
                background-image: url("../../img/sidebar/asset-h.png");
              }
              &.setting {
                background-image: url("../../img/sidebar/setting-h.png");
              }
              &.alert {
                background-image: url("../../img/sidebar/alert-h.png");
              }
              &.appcatalogue {
                background-image: url("../../img/sidebar/appcatalogue-h.png");
              }
              &.preferences {
                background-image: url("../../img/sidebar/preferences-h.png");
              }
              &.bimapping{
                background-image: url("../../img//sidebar/bimapping-h.png");
              }
              &.report{
                background-image: url("../../img//sidebar/reporting-h.png");
              }
            }
          }
        }

        &.active {
          a {
            background-color: #dbdff1;
          }

          .icon {
            &.dashboard {
              background-image: url("../../img/sidebar/dashboard-h.png");
            }
            &.asset {
              background-image: url("../../img/sidebar/asset-h.png");
            }
            &.setting {
              background-image: url("../../img/sidebar/setting-h.png");
            }
            &.alert {
              background-image: url("../../img/sidebar/alert-h.png");
            }
            &.appcatalogue {
              background-image: url("../../img/sidebar/appcatalogue-h.png");
            }
            &.preferences {
              background-image: url("../../img/sidebar/preferences-h.png");
            }
            &.bimapping{
              background-image: url("../../img//sidebar/bimapping-h.png");
            }
            &.report{
              background-image: url("../../img//sidebar/reporting-h.png");
            }
          }

          .name {
            color: #1b4dffed;
          }

          > ul {
            > li {
              &.active {
                a {
                  color: #383874;
                  background-color: #dbdff1;
                  span {
                    display: inline-flex;
                    vertical-align: middle;
                  }
                }
              }
              a {
                color: #b8b4cc;
                background-color: #ffffff;
                &:hover {
                  color: #383874;
                  background-color: #dbdff1;
                }
              }
            }
          }
        }

        > ul {
          display: flex;
          width: 100%;
          margin: 10px 0 0 0;
          padding: 0 10px;
          overflow: visible;
          height: auto;
          flex-wrap: wrap;

          &.submenuclose {
            display: none;
          }

          > li {
            margin: 0 0 10px 0;
            padding: 0;
            display: flex;
            width: 100%;
            > a {
              width: 100%;
              display: flex;
              font-size: 12px;
              line-height: 24px;
              padding-top: 5px;
              padding-bottom: 5px;
              padding-left: 10px;
              padding-right: 10px;
              color: #b8b4cc;
              border-radius: 5px;
              background-color: #ffffff;
              span {
                display: none;
                margin-left: auto;
                margin-top: 6px;
              }
              &:hover {
                color: #383874;
                background-color: #dbdff1;
                span {
                  display: inline-flex;
                  vertical-align: middle;
                }
              }
            }
          }
        }
      }
    }
  }
}
